@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap');

body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-image: url('../public/grades.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
@media (min-width: 1024px) {
    body {
        background-image: url('../public/bg.png');
    }
}
.container {
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.result {
    margin-top: 20px;
    text-align: center;
}
label {
    display: block;
    margin-bottom: 5px;
}
input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
button {
    background-color: #fdcb44;
    font-size: 1.125rem;
    color: black;
    font-family: 'Bubblegum Sans', cursive;
    width: 290px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 0 auto;
}
button:hover {
    background-color: #e0b839;
}
.custom-css-heading {
    color: black;
    text-shadow: 4px 0 0 #e4f0ff, 0 4px 0 #e4f0ff, -4px 0 0 #e4f0ff,
                 0 -4px 0 #e4f0ff, 5px 0 0 black, 0 5px 0 black, 
                 -5px 0 0 black, 0 -5px 0;
    font-family: 'Bubblegum Sans', cursive;
    font-weight: bold;
    font-size: 2rem;
    text-align: center; 
}
@media (min-width: 1024px) {
    .custom-css-heading {
        font-size: 3.5rem;
    }
}
